import {Responsive, ResponsiveSection} from '../../../styles.interface';
import {config} from '@/product/config.json';

const HeaderNo3: ResponsiveSection = {
  HeaderSectionStyled: {
    xs: {
      'background-position-y': 'calc(50% + 20px)',
    },
  }
};

const Register: ResponsiveSection = {
  CreditCardBox: {
    xs: {
      background: config.STYLEGUIDE.colors.lighter,
      'border-color': config.STYLEGUIDE.colors.lighter,
    },
  }
};

const Player: ResponsiveSection = {
  FavoriteButton: {
    xs: {
      background: config.STYLEGUIDE.colors.info2,
    },
  }
};

const OldPasswordForm: ResponsiveSection = {
  ButtonStyled: {
    xs: {
      background: config.STYLEGUIDE.colors.brand,
    },
  },
  ButtonStyledHover: {
    xs: {
      background: config.STYLEGUIDE.colors.infoHover,
    },
  },
};

const Sections: Responsive = {
  Register,
  Player,
  OldPasswordForm,
  HeaderNo3,
};

export default Sections;
